import { format, subDays } from 'date-fns';
import { saveAs } from 'file-saver';
import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { useMemo, useState } from 'react';
import { useClient, useQuery } from 'urql';

import {
  Card,
  CardBody,
  DateField,
  Form,
  SelectField,
  SelectOption,
  SubmitButton,
} from '@/components';
import { useTranslate } from '@/contexts';
import { BrandEnum } from '@/globalTypes';
import {
  ClosingBalancesReport,
  ClosingBalancesReportVariables,
} from './__generated__/ClosingBalancesReport';
import { ClosingBalancesReportBox } from './__generated__/ClosingBalancesReportBox';
import { SanityClosingBalancesReportBlockStaticQuery } from './__generated__/SanityClosingBalancesReportBlockStaticQuery';

const BLOCK_STATIC_QUERY = graphql`
  query SanityClosingBalancesReportBlockStaticQuery {
    sanityClosingBalancesReportBlock {
      title {
        ...LocaleString
      }
      selectBrand {
        ...LocaleString
      }
      brandLabel {
        ...LocaleString
      }
      fromLabel {
        ...LocaleString
      }
      toLabel {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const QUERY = gql`
  query ClosingBalancesReportBox {
    viewer {
      id
      brands {
        code
        name
      }
    }
  }
`;

const REPORT_QUERY = gql`
  query ClosingBalancesReport(
    $brand: BrandEnum
    $initialDate: LocalDate!
    $finalDate: LocalDate!
  ) {
    viewer {
      id
      exportClosingBalancesReport(
        brand: $brand
        initialDate: $initialDate
        finalDate: $finalDate
      ) {
        url
      }
    }
  }
`;

type FormValues = {
  brand: BrandEnum | '';
  from: Date;
  to: Date;
};

const ClosingBalancesReportBlock = () => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { t } = useTranslate();
  const client = useClient();
  const [{ data }] = useQuery<ClosingBalancesReportBox>({
    query: QUERY,
  });

  const today = useMemo(() => new Date(), []);

  const block = useStaticQuery<SanityClosingBalancesReportBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityClosingBalancesReportBlock;

  const brandOptions = useMemo(() => {
    const brandOptions: SelectOption[] =
      data?.viewer.brands.map((brand) => ({
        label: brand.name,
        value: brand.code,
      })) ?? [];

    const options: SelectOption[] = [
      {
        label: t(block?.selectBrand),
        value: '',
      },
      ...brandOptions,
    ];

    return options;
  }, [data, block?.selectBrand, t]);

  const defaultValues: FormValues = {
    brand: '',
    from: subDays(new Date(), 1),
    to: new Date(),
  };

  const onSubmit = ({ brand, from, to }: FormValues) => {
    if (!from || !to) {
      return;
    }

    setIsGenerating(true);

    client
      .query<ClosingBalancesReport, ClosingBalancesReportVariables>(
        REPORT_QUERY,
        {
          brand: brand || null,
          initialDate: format(from, 'yyyy-MM-dd'),
          finalDate: format(to, 'yyyy-MM-dd'),
        },
        {
          requestPolicy: 'network-only',
        },
      )
      .toPromise()
      .then((res) => {
        const url = res.data?.viewer.exportClosingBalancesReport.url;

        if (url) {
          saveAs(url, 'players-balance-report.csv');
        }
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  if (!block) {
    return null;
  }

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <div className="p-3">
          <Form
            defaultValues={defaultValues}
            onSubmit={onSubmit}
            className="grid gap-3"
          >
            <SelectField
              name="brand"
              id="closing-balances-report-block__brand"
              title={t(block.brandLabel)}
              options={brandOptions}
            />
            <DateField
              name="from"
              id="closing-balances-report-block__from"
              title={t(block.fromLabel)}
              maxDate={today}
              required
            />
            <DateField
              name="to"
              id="closing-balances-report-block__to"
              title={t(block.toLabel)}
              maxDate={today}
              required
            />
            <SubmitButton value={t(block.submit)} disabled={isGenerating} />
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default ClosingBalancesReportBlock;
